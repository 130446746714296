
<template lang="pug">
  #catalog-page
    .grid-layout-container.alignment-block.pb-6(style="width:100%;")
      vs-row(vs-w='12' vs-type='flex', vs-justify='flex-end')

        vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='12'   )
          span(style="margin-right:10px; margin-top: -50px !important; margin-bottom: 50px !important;") QR
          vs-switch(  v-model='switch1'  style="margin-right:20px; margin-top: -50px !important; margin-bottom: 50px !important;" )

        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='12'   )
          vs-button(vs-type="flex" vs-align="center" vs-justify="center" style="width:100%; padding-left:0; padding-right:0; margin-top: -50px !important; margin-bottom: 50px !important;" color="rgb(74, 74, 74)" @click="$router.push('/backoffice/stock')"  type="filled"  )
            i.material-icons(style="font-size:18px; margin-right:5px;vertical-align:middle;") keyboard_backspace
            span กลับหน้าสต็อค

        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='12'  )
          vs-button(vs-type="flex" vs-align="center" vs-justify="center" style=" margin-top: -50px !important;  margin-bottom: 50px !important; width:100%; padding-left:0; padding-right:0; background: rgb(74, 74, 74); " @click="printInvoice()"  type="filled"  )
            i.material-icons(style="font-size:18px; margin-right:5px;vertical-align:middle;") print
            span พิมพ์

    //- .flex.flex-wrap.items-center.justify-between
    //-   vx-input-group.mb-base.mr-3
    //-   .flex.items-center
    //-     vs-button.mb-base.mr-6(icon-pack="feather" icon="icon icon-file" @click="printInvoice") Print
    //-   .flex.items-center
    //-     vs-button.mb-base.mr-6(icon-pack="feather" icon="icon icon-file" @click="printInvoice") กลับหน้าสต็อค
    #invoice-page(style="margin-top:-60px !important;")
      .invoice-container.page.pagebreak( v-for="(n,i) in pageNumQr" :key="i")
          //- ************* QRRRRR CODEEEE *************
          vs-row(vs-w='12' v-if="qrPrint === 2")
            vs-col.px-2.thecol( v-for="(item, ind) in (transformListQr[i].length * 2)" :key="ind" vs-type='flex',vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='12')
              .card3.colcard(v-if="item%qrPrint === 1")
                //- รูปภาพ
                .limit-height.pb-1
                  //- p {{Math.floor(item/2)}}  {{item}}  {{transformListQr[i].length}}
                  img.px-3.py-1.responsive.rounded.product-image(:src="getImagepath(transformListQr[i][Math.floor(item/qrPrint)].mainImage)"  alt="user-upload" )
                //- บาร์โค้ด
                .grid-layout-container.alignment-block.pt-1.barcode-container(style="width:100%; ")
                  vs-row(vs-w='12')
                    vs-col.px-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                      .grid-layout-container.alignment-block(style="width:100%;")
                        vs-row(vs-w='12' vs-type='flex', vs-justify='center' )
                          .limit-barcode
                            vue-barcode(style="width:100%;" v-bind:value="transformListQr[i][Math.floor(item/qrPrint)].code" tag="img") ไม่พบบาร์โค้ด
                        vs-row(vs-w='12' vs-type="flex" vs-justify="center")
                          p.item-code {{transformListQr[i][Math.floor(item/qrPrint)].code}}


                //- ชื่อสินค้า
                .grid-layout-container.alignment-block.pb-1.pt-3(style="width:100%;")
                  vs-row(vs-w='12'  vs-type="flex" vs-justify="center")
                    .limit-name
                      p.px-6.item-name {{ getName18Char( transformListQr[i][Math.floor(item/qrPrint)].name )   }}
                //- ค่าเช่าชุด
                .grid-layout-container.alignment-block.pb-1(style="width:100%;")
                  vs-row(vs-w='12'  vs-type="flex" vs-justify="center")
                    p.item-price ค่าเช่า : {{formatPrice(transformListQr[i][Math.floor(item/qrPrint)].rentalPrice)}}
                //- ค่าประกัน
                .grid-layout-container.alignment-block.pb-2.bail-container(style="width:100%;")
                  vs-row(vs-w='12'  vs-type="flex" vs-justify="center")
                    p.item-price ประกัน : {{formatPrice(transformListQr[i][Math.floor(item/qrPrint)].bail)}}
                //- ขนาด สี
                .grid-layout-container.alignment-block.pb-2.color-container(style="width:100%; max-height: 20px !important;")
                  vs-row(vs-w='12'  vs-type="flex" vs-justify="center")
                    vs-col.px-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                      p.item-color {{ getName12Char(getColor(transformListQr[i][Math.floor(item/qrPrint)].color)) }} ({{ getName7Char(getSize(transformListQr[i][Math.floor(item/qrPrint)].size) ) }})
                    //- vs-col.px-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='6', vs-sm='6', vs-xs='12')
                    //-   p.item-color ขนาด : {{transformListQr[i][Math.floor(item/qrPrint)].size}}

              .card3.colcard(v-else)
                //- .limit-height.pb-1
                //-   p {{Math.floor(item/qrPrint)-1}} {{item}}  {{transformList[i].length}}
                //- บาร์โค้ด
                .grid-layout-container.alignment-block.pt-1.barcode-container(style="width:100%; ")
                  vs-row(vs-w='12')
                    vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                      .grid-layout-container.alignment-block(style="width:100%;")
                        vs-row(vs-w='12' vs-type="flex" vs-justify="center")
                          qr-canvas(:options='theOptions(transformListQr[i][Math.floor(item/qrPrint)-1].code)')
                        vs-row(vs-w='12' class="py-4" vs-type="flex" vs-justify="center" style="font-size:1.5rem;")
                          p.item-code {{transformListQr[i][Math.floor(item/qrPrint)-1].code}}


          //- ************* NOOoooo QRRRRR CODEEEE *************
          vs-row(vs-w='12'  v-if="qrPrint === 1")
            vs-col.px-2.thecol( v-for="(item, ind) in transformList[i]" :key="ind" vs-type='flex',vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='12')
              .card3.colcard
                //- รูปภาพ
                .limit-height.pb-1
                  img.px-3.py-1.responsive.rounded.product-image(:src="getImagepath(item.mainImage)"  alt="user-upload" )
                //- บาร์โค้ด
                .grid-layout-container.alignment-block.pt-1.barcode-container(style="width:100%; ")
                  vs-row(vs-w='12')
                    vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                      .grid-layout-container.alignment-block(style="width:100%;")
                        vs-row(vs-w='12')
                          .limit-barcode
                            vue-barcode(style="width:100%;" v-bind:value="item.code" tag="img") ไม่พบบาร์โค้ด
                        vs-row(vs-w='12' vs-type="flex" vs-justify="center")
                          p.item-code {{item.code}}


                //- ชื่อสินค้า
                .grid-layout-container.alignment-block.pb-1.pt-3(style="width:100%;")
                  vs-row(vs-w='12'  vs-type="flex" vs-justify="center")
                    .limit-name
                      p.px-6.item-name {{ getName15Char( item.name )   }}
                //- ค่าเช่าชุด
                .grid-layout-container.alignment-block.pb-1(style="width:100%;")
                  vs-row(vs-w='12'  vs-type="flex" vs-justify="center")
                    p.item-price ค่าเช่า : {{formatPrice(item.rentalPrice)}}
                //- ค่าประกัน
                .grid-layout-container.alignment-block.pb-2.bail-container(style="width:100%;")
                  vs-row(vs-w='12'  vs-type="flex" vs-justify="center")
                    p.item-price ประกัน : {{formatPrice(item.bail)}}
                //- ขนาด สี
                .grid-layout-container.alignment-block.pb-2.color-container(style="width:100%; max-height: 20px !important;")
                  vs-row(vs-w='12'  vs-type="flex" vs-justify="center")
                    vs-col.px-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                      p.item-color {{ getName12Char(getColor(item.color)) }} ({{ getName7Char( getSize(item.size)) }})



</template>

<script>
// import shapeFormat from "@/mixins/shapeFormat.js";
import shapeFormat from "../../mixins/shapeFormat.js";
import VueBarcode from "vue-barcode";

const { QRCanvas } = require('qrcanvas-vue');

export default {
  mixins: [shapeFormat],
  data() {
    return {
      switch1: true,
      qrPrint: 2,
      pageNum: 1,
      pageNumQr:1,
      pageSize: 12,
      productList: [],
      transformList: [],
      transformListQr:[],
      categories: [],
      textures: [],
      colors: [],
      sizes: []
    };
  },

  watch:{
    switch1(){

      if(this.switch1 === true){
        this.qrPrint = 2;
      }else{
        this.qrPrint = 1;
      }

    }
  },

  async created() {

      var color_res = null;
      var size_res = null;
      try {
        color_res = await this.$http.get(
          this.$store.state.apiURL + "/api/color" ,
          this.$store.state.auth.apiHeader
        );
          size_res = await this.$http.get(
          this.$store.state.apiURL + "/api/size" ,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        color_res = null;
        size_res = null
      }





    // this.categories = this.$store.getters["productGroup/categories"];
    // this.textures = this.$store.getters["productGroup/textures"];
    this.colors = color_res.data;
    this.sizes = size_res.data;

    // console.log('CREATED this.colors >>> ',this.colors);


  },

  computed: {
    pageSizeQr:{
      get(){
        return Math.ceil(this.pageSize / 2);
      }
    }
  },
  async mounted() {
    // await this.$store.dispatch("productList/fetchList");
    this.productList = this.$store.getters["productList/catalog"];
    // console.log("productList >>> ", this.productList);

    this.$emit("setAppClasses", "invoice-page");

    this.transformList = this.transform(this.productList);
    // console.log("transformList >>> ", this.transformList);
    this.pageNum = this.transformList.length;

    this.transformListQr = this.transformForQr(this.productList);
    // console.log("transformListQr >>> ", this.transformListQr);
    this.pageNumQr = this.transformListQr.length;

    // ****** QR Code

  },

  methods: {
    theOptions(code){
      const options = {
                cellSize: 5,
                correctLevel: 'H',
                data: code,
            }

        // const image = new Image();

        // image.src = 'https://demo002.alexrental.app/webimage/facebook_draw.png';

        // image.onload = () => {
        // options = Object.assign({}, options, {
        //       logo: {
        //       image,
        //       },
        //   });
        // }


      return options;
    },
    getName15Char(text){
      if(text === null || text === undefined){
        return "ไม่ระบุ";
      }
      return text.substring(0,15);
    },
    getName18Char(text){
      if(text === null || text === undefined){
        return "ไม่ระบุ";
      }
      return text.substring(0,18);
    },
    getName20Char(text){
      if(text === null || text === undefined){
        return "ไม่ระบุ";
      }
      return text.substring(0,20);
    },
    getName12Char(text){
      if(text === null || text === undefined){
        return "ไม่ระบุ";
      }
      return text.substring(0,11);
    },
    getName7Char(text){
      if(text === null || text === undefined){
        return "ไม่ระบุ";
      }
      return text.substring(0,6);
    },
    getColor(code){
      // console.log('Colors >>>> ',this.colors);

      var co = this.colors.find(x => x.code === code);
      if(co === null || co === undefined){
        return "ไม่ระบุสี";
      }
      else{
        return co.description;
      }
    },
    getSize(code){
      var co = this.sizes.find(x => x.code === code);
      if(co === null || co === undefined){
        return "ไม่ระบุขนาด";
      }
      else{
        return co.code;
      }
    },
    transform(list) {
      var transform_list = [];

      var inlist = [];

      for (let i = 0; i < list.length; i++) {
        if (i % this.pageSize === 0) {
          inlist = [];
        }

        inlist.push(list[i]);

        if (i % this.pageSize === this.pageSize - 1 || i === list.length - 1) {
          transform_list.push(inlist);
        }
      }

      return transform_list;
    },

    transformForQr(list) {
      var transform_list = [];

      var inlist = [];

      for (let i = 0; i < list.length; i++) {
        if (i % this.pageSizeQr === 0) {
          inlist = [];
        }

        inlist.push(list[i]);

        if (i % (this.pageSizeQr) === (this.pageSizeQr) - 1 || i === list.length - 1) {
          transform_list.push(inlist);
        }
      }

      return transform_list;
    },


    printInvoice() {
      window.print();
    }
  },
  components: { VueBarcode, QrCanvas: QRCanvas, }
  // mounted() {}
};
</script>

<style lang="scss" scpoe >
.page {
  // width: 210mm;
  // min-height: 291mm;
  // padding: 4mm 4mm 2mm;
  margin: 0 auto;
  left: 0;

  border: 0 white solid;
  /* border-radius: 5px; */
  background: white;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
.product-image{
  padding-bottom: 10mm !important;
}
  // .colcard{
  //     margin-bottom:12mm !important;

  // }
  .card3{
      border: 0 solid white;
      min-height: 200px !important;
      width: 46mm !important;
  }



.vue-barcode-element{
   height: 70px !important;
}
.limit-height {
  height: 180px !important;
  overflow: hidden;
}
.limit-barcode {
  height: 40px !important;
  overflow: hidden;
}


.limit-name {
  height: 15px !important;
  overflow: hidden;
}
.vue-barcode-element {
  width: 100% !important;
}
.item-name {
  font-size: 0.75em;
}
.item-price {
  font-size: 0.6em;
}
.item-code {
  font-size: 0.6em;
}

.item-color {
  font-size: 0.6em;
}

.pagebreak {
  clear: both;
  page-break-after: always !important;
}
.invoice-container {
  padding: 0 5px 5px 5px !important;
}
// ********************************* //
// *********** FOR PAPER *********** //
// ********************************* //
@page {
  size: A4;
  margin: 0;
}
@media print {
  @page {size: landscape}
  html, body {
    // width: 210mm;
    // height: 297mm;
    width: 297mm;
    height: 210mm;
  }
    body {
    margin: 0 !important;
    border: 0 !important;

    // margin-top: 5mm !important;
    // background-color: black;
  }
  /* ... the rest of the rules ... */
  .invoice-container {
    padding-top: 20mm !important;

    padding: 0 !important;

  }

  .card3{
    border : 0 solid white !important;
  }

  .thecol{
    padding-bottom: 12mm !important;
  }

// *********** กรอบ IMAGE *********** //
   .limit-height {
    //  margin-top: 5mm !important;
      height: 40mm !important;
      overflow: hidden;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      display: block;
      // border: red solid 1px;
      padding-left: 15% !important;


    }

  .product-image{
    // width: 80%;
    // width: 100%;
    width: 90%;
    // width: 75%;
    // height: 100%;
    // display: flex;
    // justify-content: center;
    // margin: 0 auto !important;

  }
// *********** ตัว BARCODE *********** //
.vue-barcode-element{
   height: 18mm !important;
}

// *********** กรอบ BARCODE *********** //
  .limit-barcode {
      height: 10mm !important;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      margin-top: 0 !important;
      padding-top: 0 !important;
    }


    .limit-name {
      height: 15px !important;
      overflow: hidden;
    }

    .barcode-container{
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
    .image-container{
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
}




@media print {


  .page {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 3mm !important;
    padding-top: 0 !important;
  }



  #invoice-page {
    height: auto !important;
    margin-top: -10mm !important;
  }

  .vx-navbar-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    height: 0 !important;
  }

  .router-view {
    margin: 0 !important;
    padding: 0 !important;
  }

  .router-header {
    margin: 0 !important;
    padding: 0 !important;
  }
  .vx-navbar-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
  .items-center {
    margin: 0 !important;
    padding: 0 !important;
    height: 0 !important;
  }


//   .image-container{
//     display: flex;
//     justify-content: center;
//     // padding-left: 10%;padding-right: 10%;
// }

  .items-center {
    margin: 0 !important;
    padding: 0 !important;
  }
  .vx-navbar-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }

  .router-content {
    margin-top: 0 !important;
    padding: 0 !important;
  }

  .router-header {
    margin: 0 !important;
    padding: 0 !important;
  }
  .the-footer {
    margin-top: 0 !important;
    padding: 0 !important;
    height: 0 !important;
    display: none;
  }

  body {
    margin: 0 !important;
    background-color: white;
  }

  .invoice-page {
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }
.pagebreak
{
 margin: 0 !important;
}

    .invoice-container,
    .invoice-container * {
      margin: 0 !important;
      visibility: visible;
      box-shadow: none;

    }
    .invoice-container
    {
      margin-top:20mm !important;
    }

    .vue-barcode-element {
      width: 100% !important;
    }
    .item-name {
      font-size: 0.9rem;
    }
    .item-price {
      font-size: 0.8rem;
    }
    .item-color {
      font-size: 0.8rem;
    }

    .pagebreak {
      clear: both;
      page-break-after: always !important;
    }
  }
}
</style>
